<template>
  <CommonTooltip :tooltip="$t('auction-special-condition.tooltip-content')">
    <DynamicAuctionItemListCarIcon
      variant="orange"
      size="small"
      v-bind="$attrs"
      @click.stop="showSpecialConditionNotificationDialog = true"
    >
      <Icon name="fluent:important-32-regular" mode="css" size="30" />
    </DynamicAuctionItemListCarIcon>
  </CommonTooltip>
</template>

<script lang="ts" setup>
import { useSpecialConditionNotification } from '@autobid/ui/composables/auction/useSpecialConditionNotification'

type Props = {
  auctionId: number
}

const props = defineProps<Props>()
const { showSpecialConditionNotificationDialog } =
  useSpecialConditionNotification({ auctionId: props.auctionId })
</script>
