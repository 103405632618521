import type { MaybeRef, Ref } from 'vue'
import { computed, onServerPrefetch, unref } from 'vue'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { getError } from '@autobid/strapi-integration/utils/getError'
import {
  getProfileQueryKey,
  useProfile
} from '@autobid/ui/composables/useProfile'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { useAuction } from '../useAuction'

type Options = {
  /**
   * Provide the carId if you want to accept special conditions for a specific car. You do not have to provide it if you're not going to use
   * a "handleAccept" function
   */
  carId?: MaybeRef<number>
  auctionId: number
  successCallback?: () => void
}

export const useSpecialConditionNotification = ({
  carId,
  auctionId,
  successCallback
}: Options) => {
  const informedForIds = useCookie<number[]>('specialConditionNotification', {
    maxAge: 60 * 60 * 30
  })
  const showSpecialConditionNotificationDialog = useState(
    'specialConfitionNotificationPopup',
    () => false
  ) as Ref<boolean>
  const queryClient = useQueryClient()
  const { isAuthed, sessionData } = useAutobidAuth()
  const { $customFetch } = useCustomFetch()
  const push = usePush()
  const { t } = useI18n()
  const {
    data: profile,
    revalidate: revalidateProfile,
    suspense: suspenseProfile
  } = useProfile()
  const { auction } = useAuction(auctionId)
  const doesAuctionMeetConditions = computed(() => {
    if (!auction.value) return false

    if (auction.value.stage === 'FINISHED') return false

    return !!auction.value.activeStatusesNames.includes(
      'special_conditions_notification'
    )
  })

  const specialConditionAccepted = computed(() => {
    if (!profile.value?.acceptedSpecialConditionsForAuctions) return false

    return profile.value.acceptedSpecialConditionsForAuctions.includes(
      auctionId
    )
  })

  const shouldDisplayDialog = computed(() => {
    if (!isAuthed.value) return false

    if (informedForIds.value && informedForIds.value?.includes(auctionId)) {
      return false
    }

    if (!doesAuctionMeetConditions.value) return false

    if (specialConditionAccepted.value) {
      return false
    }

    return true
  })

  const markAsInformed = () => {
    if (informedForIds.value && informedForIds.value?.includes(auctionId))
      return

    informedForIds.value = [...(informedForIds.value || []), auctionId]
  }

  const mutationFn = () => {
    return $customFetch('/api/backend', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        queryApi: 'webapi',
        queryMethod: 'POST',
        queryUrl: unref(carId)
          ? `/v1/special-conditions/car/${unref(carId)}/accept`
          : `/v1/special-conditions/auction/${auctionId}/accept`
      }
    })
  }

  const markAuctionIdAsAccepted = () => {
    queryClient.setQueryData(
      getProfileQueryKey({ isAuthed: true, userId: sessionData.value.user.id }),
      () => {
        return {
          ...profile.value,
          acceptedSpecialConditionsForAuctions: [
            ...(profile.value.acceptedSpecialConditionsForAuctions || []),
            auctionId
          ]
        }
      }
    )
  }

  const { mutate: handleAccept, isLoading } = useMutation({
    mutationFn,
    onError: (error) => push.error(getError(error).message),
    onSuccess: () => {
      markAuctionIdAsAccepted()
      revalidateProfile()
      push.success(t('auction-special-condition.accept-success-message'))

      if (successCallback) successCallback()
    }
  })

  onServerPrefetch(suspenseProfile)

  return {
    doesAuctionMeetConditions,
    showSpecialConditionNotificationDialog,
    specialConditionAccepted,
    shouldDisplayDialog,
    isLoading,
    handleAccept,
    markAsInformed
  }
}
